// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-careers-popup-template-js": () => import("./../../../src/templates/CareersPopupTemplate.js" /* webpackChunkName: "component---src-templates-careers-popup-template-js" */),
  "component---src-templates-case-study-popup-template-js": () => import("./../../../src/templates/CaseStudyPopupTemplate.js" /* webpackChunkName: "component---src-templates-case-study-popup-template-js" */),
  "component---src-templates-page-template-js": () => import("./../../../src/templates/PageTemplate.js" /* webpackChunkName: "component---src-templates-page-template-js" */),
  "component---src-templates-services-popup-template-js": () => import("./../../../src/templates/ServicesPopupTemplate.js" /* webpackChunkName: "component---src-templates-services-popup-template-js" */)
}

