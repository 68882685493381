import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { graphql, useStaticQuery } from "gatsby"

import useOnClickOutside from "../hooks/useOnClickOutside"
import Link from "../utils/link"

import MenuIcon from "../assets/images/Vectors/menu.svg"
import CloseIcon from "../assets/images/Vectors/times.svg"
import WingspanLogo from "../assets/images/Vectors/WingspanFullMark.svg"
import "./header.scss"

const Header = () => {
  const res = useStaticQuery(menuQuery)

  const contactInformation = res.wpPost.acfMenu.menuData[0].contactInformation
  const navigationLinks = res.wpPost.acfMenu.menuData[0].navigationLinks
  const { address, contact, socialMedia } = contactInformation

  const ref = React.useRef()

  const [isVisible, setIsVisible] = React.useState(false)
  const [scrolled, setScrolled] = React.useState(false)

  useEffect(() => {
    const body = document.querySelector("body")
    isVisible
      ? (body.style.overflowY = "hidden")
      : (body.style.overflowY = "scroll")
  }, [isVisible])

  React.useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (currentScrollY > 1) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => window.removeEventListener("scroll", handleScroll);
  }, [scrolled]);

  useOnClickOutside(ref, () => setIsVisible(false))

  const toggleIsVisible = () => {
    setIsVisible(!isVisible)
  }

  const reload = () => {
    if (typeof window !== "undefined") {
      window.scrollTo({ top: 0, behavior: "smooth" })
    }
  }

  const preventClick = (e) => {
    if (isVisible) {
      e.preventDefault()
    }
  }

  const closeMenu = (e) => {
    if (isVisible) {
      setIsVisible(false)
    }
  }

  return (
    <>
      <header onClick={closeMenu} className={`header${scrolled ? ' header--scrolled' : ''}`} ref={ref}>
        <div onClick={closeMenu} className="header__container">
          <Link
            onClick={(reload, preventClick)}
            to="/"
            title="Wingspan Capital"
            className="header__logo"
          >
            <img src={WingspanLogo} />
          </Link>
          <div className="menu-trigger">
            <button onClick={toggleIsVisible}>
              <img src={MenuIcon} />
            </button>
          </div>
        </div>
        <nav
          onClick={(e) => e.stopPropagation()}
          className={`header__nav ${isVisible ? "active" : ""}`}
        >
          <div className="menu-trigger__close">
            <Link
              to="/"
              title="Site Name"
              className="header__logo--mobile"
              onClick={toggleIsVisible}
            >
              <img src={WingspanLogo} />
            </Link>
            <button onClick={toggleIsVisible}>
              <img className="close-button" src={CloseIcon} />
            </button>
          </div>
          <ul>
            {navigationLinks.map((links, index) => (
              <li key={index} onClick={reload}>
                <Link to={links.navigateLink.url} onClick={toggleIsVisible}>
                  {links.navigateLink.title}
                </Link>
              </li>
            ))}
          </ul>
          <div className="header__contact">
            <div className="address">
              <div>
                {address.suite}
                <br />
                {address.street}
                <br />
                {address.city}
              </div>
            </div>
            <div className="number">
              <Link to={`tel:${contact.telephone}`}>{contact.telephone}</Link>
            </div>
            <div className="mail">
              <Link to={`mailto:${contact.contactEmail}`}>
                {contact.contactEmail}
              </Link>
            </div>

            <div className="social">
              {socialMedia.map((social, index) => (
                <div key={index}>
                  <Link to={social.socialLink.url}>
                    {social.socialLink.title}
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </nav>
      </header>
    </>
  )
}

const menuQuery = graphql`
  query {
    wpPost(title: { eq: "Menu" }) {
      acfMenu {
        menuData {
          ... on WpPost_Acfmenu_MenuData_Menu {
            fieldGroupName
            contactInformation {
              address {
                city
                fieldGroupName
                street
                suite
              }
              contact {
                contactEmail
                telephone
              }
              socialMedia {
                socialLink {
                  title
                  url
                }
              }
            }
            navigationLinks {
              navigateLink {
                title
                url
              }
            }
          }
        }
      }
    }
  }
`

PropTypes.Header = {
  contactInformation: PropTypes.object,
  navigationLinks: PropTypes.object,
  address: PropTypes.object,
  contact: PropTypes.object,
  socialMedia: PropTypes.object,
}

export default Header
