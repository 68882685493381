import React, { useRef, useEffect } from "react"
import PropTypes from "prop-types"
import { graphql, useStaticQuery } from "gatsby"

import Fade from "react-reveal/Fade"
import Link from "../utils/link"

import WingspanLogo from "../assets/images/Vectors/WingspanFullMark.svg"
import "./footer.scss"

const Footer = () => {
  const res = useStaticQuery(footerQuery)
  const pathname = typeof window !== "undefined" ? location.pathname : ""

  const contactPageContainer = useRef()
  const contactPageSocialContainer = useRef()

  const contactInformation =
    res.wpPost.acfFooter.footerData[0].contactInformation

  const navigationLinks = res.wpPost.acfFooter.footerData[0].navigationLinks

  const {
    address,
    contact,
    copyright,
    design,
    socialMedia,
    designBy,
  } = contactInformation

  useEffect(() => {
    if (pathname === "/contact") {
      contactPageContainer.current.classList.add("contactPageContainer")
      contactPageSocialContainer.current.classList.add(
        "contactPageSocialContainer"
      )
    } else {
      contactPageContainer.current.classList.remove("contactPageContainer")
      contactPageSocialContainer.current.classList.remove(
        "contactPageSocialContainer"
      )
    }
  })

  const scrollToTop = () => {
    if (pathname === "/" && typeof window !== "undefined") {
      window.scrollTo({ top: 0, behavior: "smooth" })
    }
  }

  return (
    <Fade>
      <footer className="footer">
        <div className="footer__container">
          <div className="footer__divider" />
          <div className="footer__content">
            <div className="footer__logo">
              <Link to="/" title="Wingspan Capital">
                <img onClick={scrollToTop} src={WingspanLogo} />
              </Link>
            </div>
            <div
              ref={contactPageContainer}
              className="footer__content__container"
            >
              <div className="footer__content__links--desktop">
                <ul>
                  {navigationLinks.map((navigation, index) => (
                    <li key={index}>
                      <Link to={navigation.navigateLink.url}>
                        {navigation.navigateLink.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div
                ref={contactPageSocialContainer}
                className="footer__content__contact "
              >
                <div className="footer__content__contact__content">
                  <div className="footer__content__contact__address">
                    <div>
                      {address.suite} <br />
                      {address.street} <br />
                      {address.city}
                    </div>
                  </div>
                  <div className="footer__content__contact__details">
                    <div className="footer__content__contact__number">
                      <Link to={`tel:${contact.telephone}`}>
                        {contact.telephone}
                      </Link>
                    </div>
                    <div className="footer__content__contact__email">
                      <Link to={`mailto:${contact.contactEmail}`}>
                        {contact.contactEmail}
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="footer__content__contact__social">
                  {socialMedia.map((social, index) => (
                    <div key={index}>
                      <Link to={social.socialLink.url}>
                        {social.socialLink.title}
                      </Link>
                    </div>
                  ))}
                  <div className="footer__content__contact__copyright--desktop">
                    {copyright} <br />
                    <div className="footer__content__contact__design--desktop">
                      {`${designBy} `}
                      <a
                        className="footer__content__contact__company"
                        target={design.companyOne.target}
                        href={design.companyOne.url}
                      >
                        {`${design.companyOne.title} + `}
                      </a>{" "}
                      <a
                        className="footer__content__contact__company"
                        target={design.companyTwo.target}
                        href={design.companyTwo.url}
                      >
                        {design.companyTwo.title}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer__content__links--mobile">
            <ul>
              {navigationLinks.map((navigation, index) => (
                <li key={index}>
                  <Link to={navigation.navigateLink.url}>
                    {navigation.navigateLink.title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="footer__content__contact__copyright--mobile">
            {`${copyright} `}
            <div className="footer__content__contact__design--mobile">
              {`${designBy}  `}
              <a target={design.companyOne.target} href={design.companyOne.url}>
                {`${design.companyOne.title} + `}
              </a>{" "}
              <a target={design.companyTwo.target} href={design.companyTwo.url}>
                {design.companyTwo.title}
              </a>
            </div>
          </div>
        </div>
      </footer>
    </Fade>
  )
}

const footerQuery = graphql`
  query {
    wpPost(title: { eq: "Footer" }) {
      acfFooter {
        footerData {
          ... on WpPost_Acffooter_FooterData_Footer {
            fieldGroupName
            contactInformation {
              design {
                companyOne {
                  title
                  url
                  target
                }
                companyTwo {
                  target
                  title
                  url
                }
              }
              socialMedia {
                socialLink {
                  title
                  url
                }
              }
              address {
                city
                street
                suite
              }
              contact {
                contactEmail
                telephone
              }
              copyright
              designBy
            }
            navigationLinks {
              navigateLink {
                title
                url
              }
            }
          }
        }
      }
    }
  }
`
Footer.propTypes = {
  footer: PropTypes.object,
  contactInformation: PropTypes.object,
  navigationLinks: PropTypes.object,
}

export default Footer
